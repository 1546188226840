import axios from "axios";

const api = axios.create({
  baseURL: "https://fyoz5belzej3i25oganballxay0dnubh.lambda-url.us-east-1.on.aws",
  headers: {
    "x-newrelic-id": "VwMPVl9UCBABUVBUBgYBUlcF"
  }
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem("token");
  if (token && window.location.hash.indexOf("#/admin") === 0) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.hash.indexOf("#/admin") === 0
    ) {
      localStorage.removeItem("token");
      window.location = "/#/login_admin";
    }

    if (error.name === "CanceledError") {
      return Promise.resolve({ data: {} });
    }

    return Promise.reject(error);
  }
);

export default api;
